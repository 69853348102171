.Toast {
  width: 100%;
  .firstdiv {
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 500;
    border-radius: 24px;
    text-align: left;
  }
  .success {
    display: flex;
  }
  .seconddiv {
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 500;
    border-radius: 24px;
    text-align: left;
  }
  .successpopup {
    display: flex;
    justify-content: space-between;
  }
  h4 {
    font-size: 1.375rem;
    font-weight: 500;
    padding-left: 1.5rem;
    color: white;
  }
  p {
    font-size: 1.375rem;
    font-weight: 500;
  }
  .desc {
    font-size: 0.875rem;
    font-weight: 400;
  }
  .time {
    padding-top: 3rem;
    font-size: 0.875rem;
    font-weight: 400;
  }

  .timeto {
    font-size: 0.875rem;
    font-weight: 400;
    padding-left: 3rem;
  }
  img {
    padding-top: 2rem;
    padding-right: 1rem;
  }
  .link {
    padding-top: 2rem;
    display: flex;
    font-size: 1rem;

    justify-content: space-between;
  }
  .pattern {
    padding-bottom: 4rem;
    padding-left: 1rem;
  }
}
