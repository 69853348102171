// Custom Bootstrap
// @use "bootstrap";
// Mixins
@use "mixins" as *;
// Base Styles
@use "base";
// Common
@use "common";
.bg-theme-primary {
  background-color: #1e22fb !important;
}
.bg-white {
  background-color: #fff;
}
.otpInputBox {
  margin-right: 1rem;
  input {
    width: 65px !important;
    height: 65px;
    padding: 0.5rem 0rem;
    outline: none;
    border-style: none;
    background: #ffffff;
    border: 1px solid #cdd4db;
    box-shadow: 0px 10.32px 30.96px -7.74px rgba(0, 0, 0, 0.04);
    border-radius: 10.32px;
    color: #040c22;
    font-weight: normal;
    font-size: 1.955rem;
    @media only screen and (max-width: 767px) {
      width: 100% !important;
      height: 100%;
    }
  }
}
.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: none !important;
}
.otp-loader {
  position: absolute;
  top: 50%;
  right: 50%;
  translate: 50% -50%;
  width: 100%;
  background: #1e22fb1c;
  height: 100%;
}
.corner-logo {
  position: relative;
  top: -30px;
  left: -40px;

  .circle-div {
    background: #f9fafb;
    display: flex;
    height: 13rem;
    width: 13rem;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 0 0 20px 0px rgba(16, 24, 40, 0.5);
    .caption {
      color: #1e22fb;
      font-size: 14px;
      margin: 0 1rem 0 3.6rem;
      line-height: 20px;
      font-weight: 500;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}
.cursor-not-allowed {
  cursor: not-allowed;
}
.w-max-content {
  width: max-content;
}
