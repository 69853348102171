@use "../../../assets/styles/_mixins.scss" as *;
.investcardmain {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 12px;
  row-gap: 15px;
  @include break(lg) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 18px;
    row-gap: 18px;
  }
  .Investcard-base {
    background: #ffffff;
    border-radius: 16px;
    padding: 33px 26px;
    width: 100%;
    position: relative;
    height: 220px;
    overflow: hidden;
    display: flex !important;
    align-items: center !important;
    justify-content: center;
    text-align: center;

    .card-headIC {
      display: flex !important;
      align-items: center !important;
      justify-content: center;
      text-align: center;
      .card-title {
        color: #005e9e;
        font-weight: 600;
        font-size: 0.875rem;
        height: 34px;
        overflow: hidden;
        text-align: center;
        margin-top: 10px;
      }
      .icon-ICD {
        width: 64px;
        height: 64px;
      }
    }
    .comingsoon-label {
      position: absolute;
      top: 0;
      right: 0;
    }
    .Investcard-btn {
      width: 100%;
      display: block;
      border: 1.3px solid #1a3e6d;
      border-radius: 99rem;
      padding: 9px;
      max-width: 120px;
      color: #1a3e6d;
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 13px;
      margin-top: 15px;
    }
  }
}

.money-text {
  height: 15px;
}

.blue-card-head-fix {
  max-width: 66%;
  position: absolute;
  width: 100%;
  z-index: 1;
}
.set-z {
  z-index: 91;
}
.invest-card {
  color: #ffffff;
  .investback {
    cursor: pointer;
  }
}
.card-head-box-over {
  width: 500px;
  height: 100%;
}

.card {
  padding: 10px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.13);
  // background: skyblue;
  border-radius: 4px;
  font-family: "Helvetica", sans-serif;
  display: flex;
  flex-direction: column;
  height: auto;
  width: auto;
}

.card__body {
  display: flex;
  flex: 1 0 auto;
  align-items: flex-end;
  justify-content: center;
}

.card__actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.corporate_btn {
  width: max-content;
  background: rgba(#1a3e6d, 1);
  box-shadow: 0px 9.792px 19.584px -7.344px rgba(0, 82, 103, 0.32);
  border-radius: 34.272px;
  color: #fff;
  padding: 0.5rem 2rem;
  font-weight: normal;
  font-size: 1rem;
  outline: none;
  border-style: none;

  &:disabled {
    background: rgba(#1a3e6d, 0.5) !important;
  }
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 5px;
  height: 5px;
  margin-top: -30px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.modal-pop-iframe {
  margin-top: 14px;
  font-size: 16px;
  font-weight: 700;
}
.iframe-grid {
  position: absolute;
  bottom: 1px;
  padding-right: 10px;
}

.comfirmationAlertBtn {
  height: 40px;
  color: #25680e !important;
}
.cancelAlertBtn {
  height: 40px;
  color: red !important;
}
.alertModal {
  border-radius: 10px !important;
}
.main-popup-Div {
  display: grid;
  justify-content: space-around;
}
.popUp-grid {
  display: flex;
}
.autoSuggest {
  .MuiAutocomplete-listbox {
    background-color: whitesmoke !important;
  }
  .css-1rndd9j-MuiPopper-root-MuiAutocomplete-popper {
    background-color: black !important;
  }
}
.modal-text-break {
  white-space: pre-wrap;
  min-height: 30px;
  font-size: 14px;
  font-weight: 600;
  margin: 10px;
  color: #d19a1e;
}
.modal-text-body {
  min-height: 30px;
  font-size: 15px;
  font-weight: bold;
  margin: 10px;
}
.modal-action-body {
  display: flex;
  justify-content: right;
}
.margin-right-5 {
  margin-right: 5px;
}
.popUp-cancel-btn {
  position: absolute !important;
  top: -1rem !important;
  right: -1rem !important;
  background: #bfaeae !important;
  border-radius: 50% !important;
}
